import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { reduxStore } from './store/reduxStore';
import { MsalProvider, AuthenticatedTemplate, MsalAuthenticationTemplate, MsalAuthenticationResult } from '@azure/msal-react';
import { InteractionType, EventMessage, EventType, AuthenticationResult, PublicClientApplication, RedirectRequest, AccountInfo } from '@azure/msal-browser';
import reportWebVitals from './reportWebVitals';
import AppErrorBoundry from './shell/AppErrorBoundry';
import { appConfig } from './shell/appConfig';
import { initMsalInstance } from './services/auth/msalHelper';
import { telemetryService } from './services/TelemetryService/TelemetryService';
import { commonStyles } from './common/common.styles';
import { App } from './App';
import { initializeIcons } from '@fluentui/react';
import './index.styles';

initializeIcons();

// Check if the URL has encoded section. See the msalConfig.ts for how the querystring will be encoded if needed.
const encodeToken: string = '_enc_';
let redirAfterMsalInit: string = '';
// Using window.location.pathname instead of window.location.href as href includes the auth token.
if (window.location.pathname.indexOf(encodeToken) > 0) {
    const startIndex: number = window.location.pathname.indexOf(encodeToken);
    const endIndex: number = window.location.pathname.lastIndexOf(encodeToken) + encodeToken.length;
    let encodedPart: string = window.location.pathname.substring(startIndex, endIndex);
    encodedPart = encodedPart.replaceAll(encodeToken, '');
    const decodedHref: string = window.location.pathname.substring(0, startIndex) + decodeURIComponent(encodedPart) + window.location.pathname.substring(endIndex);
    // Store the decoded href to be used after MSAL init.
    redirAfterMsalInit = decodedHref;
}

const appRootElemId: string = 'app';
const rootElem: HTMLElement = document.getElementById(appRootElemId) as HTMLElement;
const root: ReactDOM.Root = ReactDOM.createRoot(rootElem);

/**
 * Error component used with MSAL.
 * @param msalAuthResult MSAL authentication result. 
 * @returns JSX component.
 */
const ErrorComponent: React.FunctionComponent<MsalAuthenticationResult> = (msalAuthResult: MsalAuthenticationResult) => {
    return (
        <div className={commonStyles.errorText}>
            An authentication error occurred: {msalAuthResult.error ? msalAuthResult.error.errorCode : 'unknown error'}
        </div>
    );
}

/**
 * Loading component used with MSAL.
 * @returns JSX component.
 */
const LoadingComponent: React.FunctionComponent = () => {
    return (
        <div className={commonStyles.absoluteCenter}>
            Authenticating, please wait...
        </div>
    );
}

/**
 * Load config.
 * @returns True if config loaded, otherwise false.
 */
const loadConfig = async (): Promise<boolean> => {
    try {
        await appConfig.loadConfig();
        return true;
    } catch {
        const content: JSX.Element = (
            <div className={`${commonStyles.errorText} ${commonStyles.absoluteCenter}`}>Failed to load config.</div>
        );

        root.render(content);

        return false;
    }
}

/**
 * Initialize telemetry.
 */
const initTelemetry = (): void => {
    telemetryService.initialize(appConfig.current.instrumentation.appInsightsInstrumentationKey);
}

/**
 * Returned from initMsal function.
 */
interface IMsalInitResult {
    msalInstance: PublicClientApplication;
    loginRedirectRequest: RedirectRequest;
}

/**
 * Initialize MSAL.
 * @returns Init result object.
 */
const initMsal = async (): Promise<IMsalInitResult | undefined> => {
    let msalInstance: PublicClientApplication | undefined = undefined;
    try {
        msalInstance = await initMsalInstance();

        await msalInstance.handleRedirectPromise();

        const accounts: AccountInfo[] = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            msalInstance.setActiveAccount(accounts[0]);
        }

        msalInstance.addEventCallback(async (event: EventMessage) => {
            if (msalInstance && event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                const payload: AuthenticationResult = event.payload as AuthenticationResult;
                const account: AccountInfo = payload.account;
                msalInstance.setActiveAccount(account);
            }
        });
    } catch {
        const content: JSX.Element = (
            <div className={`${commonStyles.errorText} ${commonStyles.absoluteCenter}`}>Failed to initialize MSAL.</div>
        );

        root.render(content);

        return undefined;
    }

    const loginRedirectRequest: RedirectRequest = {
        scopes: [appConfig.current.msal.graphOpenIdScope, appConfig.current.msal.graphUserReadScope]
    };

    if (msalInstance) {
        return {
            msalInstance,
            loginRedirectRequest
        };
    }

    return undefined;
};

(async () => {
    if (await loadConfig()) {
        initTelemetry();
        const initMsalResult: IMsalInitResult | undefined = await initMsal();

        if (initMsalResult) {
            if (redirAfterMsalInit) {
                // Redirect to the decoded href.
                console.log('Redirecting to decoded href: ', redirAfterMsalInit);
                window.location.href = redirAfterMsalInit;
            }

            const content: JSX.Element = (
                // Disabling strict mode check. The Fluent UI controls in @m365-admin have tons of deprecated things
                // like componentWillReceiveProps lifecycle method. Strict mode check will flag all these things and
                // make the console log noisy. Enable only when we want to find issues in our own components.
                // This React.StrictMode could be used individually in our own component code on a case by case basis.
                // See: https://reactjs.org/docs/strict-mode.html
                // <React.StrictMode>
                <Provider store={reduxStore}>
                    <AppErrorBoundry>
                        <MsalProvider instance={initMsalResult.msalInstance}>
                            <MsalAuthenticationTemplate
                                interactionType={InteractionType.Redirect}
                                authenticationRequest={initMsalResult.loginRedirectRequest}
                                errorComponent={ErrorComponent}
                                loadingComponent={LoadingComponent}>
                            </MsalAuthenticationTemplate>
                            <AuthenticatedTemplate>
                                <App />
                            </AuthenticatedTemplate>
                        </MsalProvider>
                    </AppErrorBoundry>
                </Provider>
                // </React.StrictMode>
            );

            root.render(content);
        }
    }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
